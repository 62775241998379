/* .container-mtt {
	justify-content: center;
	align-items: center;
	background: url('../../assets/images/bg1.jpg') no-repeat center center/cover;
	background-attachment: fixed;
	padding: 100px 120px;
	color: white;
	margin: auto;
	position: relative;
	display: flex; 
} */

.container-mtt {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../assets/images/bg1.jpg') no-repeat center center/cover;
    background-attachment: fixed;
    padding: 100px 120px;
    color: white;
    margin: auto;
    position: relative;
    overflow: hidden; /* Prevents overflow */
    animation: warpAnimation 10s linear infinite, scaleAnimation 10s infinite alternate; /* Infinite horizontal warp */
}

@keyframes warpAnimation {
    0% {
        background-position: 0% 50%; /* Start at original position */
    }
    100% {
        background-position: 100% 0%; /* Move background to the right */
    }
}
@keyframes scaleAnimation {
    0% {
        background-size: cover; /* Original size */
    }
    100% {
        background-size: 110%; /* Scale up to 110% */
    }
}

.overlay-mtt {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 1, 14, 0.9);
	z-index: 1;
}

.inner-container-mtt {
	display: flex;
	flex-direction: column;
	gap: 50px;
	position: relative;
	z-index: 2;
	text-align: left;
}

.title-mtt {
	font-size: 32px;
	font-family: azonix;
	margin: 0;
	margin-bottom: 10px;
	padding: 0;
	text-align: center;
	background: linear-gradient(90deg, #E99A4E, #fffdf8);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.subtitle-mtt {
	margin: auto;
	width: 60%;
	text-align: center;
	font-family: neuropolitical;
	font-size: 14px;
}

.grid-mtt {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 0;
    z-index: 2;
}

.grid-item-mtt {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	color: #ffffff;
	text-align: left;
}
.team-image-mtt {
	width: 100%;
	height: 300px;
	border-radius: 20px;
}

.img-1-mtt {
	background: url('../../assets/images/jude.png') no-repeat center center/cover;
}
.img-2-mtt {
	background: url('../../assets/images/neye.png') no-repeat center center/cover;
}
.img-3-mtt {
	background: url('../../assets/images/chuks.png') no-repeat center center/cover;
}
.img-4-mtt {
	background: url('../../assets/images/dan.png') no-repeat center center/cover;
}
.img-5-mtt {
	background: url('../../assets/images/bello.jpg') no-repeat center center/cover;
}

.details-mtt {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;
	width: 100%;
}
.details-mtt div {
	margin: 0;
	padding: 0;
}
/* .details-mtt div h3, */
.details-mtt div p {
	background: linear-gradient(90deg, #E99A4E, #fffdf8);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.details-mtt div h3 {
	margin-bottom: 5px;
}

.grid-item-mtt h3 {
	font-size: 18px;
	font-family: azonix;
	margin: 10px 0;
	color: #fff;
	margin-bottom: -5px;
}

.grid-item-mtt img {
    width: 30px;
    font-family: neuropolitical;
}

.grid-item-mtt p {
    font-family: neuropolitical;
    font-size: 14px;
}
.icon-box-mtt {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 10px;
	align-items: center;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .container-mtt {
        padding: 100px 50px;
        height: auto;
        flex-direction: column;
    }

    .title-mtt {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .grid-mtt {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .title-mtt {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .grid-mtt {
        grid-template-columns: 1fr; /* Single column on smaller screens */
    }

    .grid-item-mtt h3 {
        font-size: 16px;
    }

    .grid-item-mtt p {
        font-size: 12px;
    }

    .grid-item-mtt img {
        width: 25px;
    }
}

@media (max-width: 450px) {
	.subtitle-mtt {
		margin: auto;
		width: 100%;
		text-align: center;
		font-family: neuropolitical;
		font-size: 14px;
	}
}
