/* General styles */
.header {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  padding: 10px 40px;
  background: transparent;
  transition: background 0.3s ease;
  z-index: 100;
  color: #ffffff;
}

.header-scrolled {
  background-color: #000000dd;
}

.logo {
  width: 120px;
  background: url('../../assets/images/logo.png') no-repeat center center/contain;
  color: white;
  transition: transform 0.3s ease;
}
.logo:hover {
  cursor: pointer;
  animation: headerScaleAnimation 0.5s infinite alternate; /* Infinite scaling */
}

@keyframes headerScaleAnimation {
  0% {
    transform: scale(1); /* Original size */
  }
  100% {
    transform: scale(1.1); /* Scale up to 110% */
  }
}

nav ul {
  display: flex;
  align-items: center;
  list-style: none;
}

nav ul li a {
  text-decoration: none;
  color: white;
}

nav ul li {
  margin-right: 20px;
  font-family: 'Neuropolitical';
  transition: background .5s;
}
nav ul li:hover {
  cursor: pointer;
  background: linear-gradient(90deg, #EA00E3, #E99A4E);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-primary {
  margin-right: 10px;
  font-family: 'Neuropolitical';
  padding: 20px 40px;
  border: none;
  border-radius: 200px;
  background: linear-gradient(90deg, #EA00E3, #E99A4E, #242ffc);
  background-size: 200% 200%;
  color: white;
  font-size: 16px;
  cursor: pointer;
  animation: gradientAnimation 3s ease infinite; /* Infinite gradient animation */
  transition: background 0.5s ease, transform 0.5s ease;
}

@keyframes gradientAnimation {
  0% {
      background-position: 0% 50%; /* Start at left */
  }
  50% {
      background-position: 100% 50%; /* Move to right */
  }
  100% {
      background-position: 0% 50%; /* Move back to left */
  }
}


.btn-primary:hover {
  /* background: linear-gradient(90deg, #E99A4E, #EA00E3); */
  transform: scale(1.1);
  cursor: pointer;
  -webkit-text-fill-color: black;
}

/* .header-btn {
  font-family: neuropolitical;
  border: 1px solid white;
  padding: 15px 25px;
  border-radius: 25px;
  background: linear-gradient(90deg, #EA00E3, #E99A4E);
  color: white;
  transition: color .5s ease-in-out, -webkit-text-fill-color .5s ease-in-out;
}

.header-btn:hover {
  background-color: white;
  -webkit-text-fill-color: black;
  color: black;
  cursor: pointer;
} */

/* Side Navigation (Mobile Menu) */
.side-nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  background-color: black;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 80px;
}

.side-nav a {
  padding: 15px 30px;
  text-decoration: none;
  font-size: 18px;
  margin-right: 20px;
  font-family: 'Neuropolitical';
  color: white;
  display: block;
  transition: 0.3s;
}

.side-nav a:hover {
  background-color: #212022;
  cursor: pointer;
}

.side-nav .close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 36px;
  margin-left: 50px;
}

.side-nav-open {
  width: 350px;
  background-color: #000000dd;
}

/* Hamburger Icon */
.hamburger {
  display: none;
  position: relative;
  margin-right: 20px;
  width: 30px;
  height: 30px;
  background: url('../../assets/images/Hamburger-icon-right.png') no-repeat center center/contain;
  cursor: pointer;
  z-index: 200px;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .header {
    padding: 5px 15px;
    align-items: center;
  }
  .logo {
    width: 60px;
    height: 60px;

  }
  nav ul {
    display: none;
  }

  .hamburger {
    display: block;
  }
}
