.container-ca {
	display: flex;
	align-items: center;
	height: 100vh;
	background: url('../../assets/images/bg3.jpg') no-repeat center center/cover;
	padding: 100px 120px;
	color: white;
	margin: auto;
	position: relative;
}

.overlay-ca {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(23, 10, 22, 0.8), rgba(58, 32, 7, 0.8));
	z-index: 1;
}

.inner-container-ca {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	justify-content: center;
	z-index: 2;
	padding: 50px 0px;
}
.text-div-ca {
	padding: 10px;
}
.text-div-ca h2 {
	font-family: azonix;
	margin-bottom: 20px;
}

.text-div-ca p {
	font-family: neuropolitical;
	line-height: 20px;
}

.sample-image-ca {
	width: 300px;
	height: 60vh;
	border-radius: 20px;
	background-size: cover;
}

.image-1 {
	background: url('../../assets/images/hair-lady.png') no-repeat center center/cover;
}

.image-2 {
	background: url('../../assets/images/epic-man.png') no-repeat center center/cover;
}

.image-3 {
	background: url('../../assets/images/suit-man.png') no-repeat center center/cover;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
	.container-ca {
		flex-direction: column;
		padding: 50px 20px;
		height: auto;
	}

	.inner-container-ca {
			flex-direction: column;
			align-items: center;
			border-radius: 10px;
	}

	.sample-image-ca {
			width: 350px;
			height: 50vh;
			background-position: top center;
	}
}

@media (max-width: 768px) {
	.sample-image-ca {
			width: 100%;
			height: 50vh;
			border-radius: 10px;
	}

	.text-div-ca h2 {
			font-size: 24px;
	}

	.text-div-ca p {
			font-size: 14px;
	}
}
@media (max-width: 450px) {
	.sample-image-ca {
			width: 90%;
			height: 40vh;
			border-radius: 10px;
	}

	.text-div-ca h2 {
			font-size: 24px;
	}

	.text-div-ca p {
			font-size: 14px;
	}
}