/* Base styles */
* {
  box-sizing: border-box;
  margin: 0;
}

.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  padding: 50px 70px;
  overflow: hidden;
  color: white;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg4.jpg') no-repeat center center/cover;
  background-attachment: fixed;
  transform: translate(-50%, -50%);
  animation: heroScaleAnimation 10s infinite alternate ease-in-out;
  z-index: 0;
}

@keyframes heroScaleAnimation {
  0% {
    transform: translate(-50%, -50%) scale(1); /* Original size */
  }
  100% {
    transform: translate(-50%, -50%) scale(2.5); /* Scale up to 105% */
  }
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.content {
  margin-top: 150px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: 'Azonix';
  z-index: 2;
}

.content h1 {
  margin: 0;
  background: linear-gradient(90deg, #fff, #702A90);
  font-size: 48px;
  line-height: 60px;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.content p {
  font-family: neuropolitical;
  background: linear-gradient(90deg, #E99A4E, #fffdf8);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-buttons {
  display: flex;
  gap: 20px;
}

/* .btn-primary {
  margin-right: 10px;
  font-family: neuropolitical;
  padding: 20px 40px;
  border: none;
  border-radius: 200px;
  background: linear-gradient(90deg, #EA00E3, #E99A4E);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.5s ease, transform 0.5s ease;
} */

.btn-primary {
  margin-right: 10px;
  font-family: 'Neuropolitical';
  padding: 20px 40px;
  border: none;
  border-radius: 200px;
  background: linear-gradient(90deg, #EA00E3, #E99A4E, #242ffc);
  background-size: 200% 200%;
  color: white;
  font-size: 16px;
  cursor: pointer;
  animation: gradientAnimation 3s ease infinite; /* Infinite gradient animation */
  transition: background 0.5s ease, transform 0.5s ease;
}

@keyframes gradientAnimation {
  0% {
      background-position: 0% 50%; /* Start at left */
  }
  50% {
      background-position: 100% 50%; /* Move to right */
  }
  100% {
      background-position: 0% 50%; /* Move back to left */
  }
}


.btn-primary:hover {
  /* background: linear-gradient(90deg, #E99A4E, #EA00E3); */
  -webkit-text-fill-color: black;
  transform: scale(1.1);
  cursor: pointer;
}

.btn-secondary {
  border: 1px solid white;
  padding: 10px 20px;
  font-family: neuropolitical;
  border-radius: 50px;
  background: transparent;
  color: white;
}
.btn-secondary:hover {
  /* background: linear-gradient(90deg, #E99A4E, #EA00E3); */
  background: linear-gradient(90deg, #E99A4E, #EA00E3);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.image-div {
  flex: 1;
  z-index: 2;
  width: 100%;
  height: 800px;
  background: url('../../assets/images/hero-image.png') no-repeat bottom left/contain;
  margin-bottom: 100px;
}

/* Responsive Styles - Media Query */
@media (max-width: 900px) {
  .hero-section {
    flex-direction: column;
    padding: 60px 40px;
  }

  .content h1 {
    font-size: 2.4rem;
    line-height: 1.2;
    text-align: left;
  }

  .content p {
    font-size: 1rem;
    text-align: left;
  }

  .hero-buttons {
    justify-content: flex-start;
    gap: 5px;
  }

  .btn-primary, .btn-secondary {
    padding: 15px 30px; /* Scale down button size */
    font-size: 14px;
  }

  .image-div {
    display: none;
  }
}

/* For extra small screens (mobile) */
@media (max-width: 480px) {
  .hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
    padding-top: 30%;
  }

  .content h1 {
    font-size: 2.5rem;
  }

  .content p {
    font-size: 0.9rem;
  }

  .btn-primary, .btn-secondary {
    padding: 12px 25px;
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  animation: slide-in-left 1s ease forwards;
}

.slide-in-right {
  animation: slide-in-right 1.2s ease forwards;
}


