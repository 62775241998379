.container-faq {
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../../assets/images/bg6.jpg') no-repeat center center/cover;
    padding: 100px 120px;
    color: white;
    margin: auto;
    position: relative;
    display: flex;
}

.overlay-faq {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(4, 1, 14, 0.9);
    z-index: 1;
}

.inner-container-faq {
    position: relative;
    z-index: 2;
    text-align: center;
}

.title-faq {
    font-size: 32px;
    font-family: azonix;
    margin-bottom: 50px;
    background: linear-gradient(90deg, #E99A4E, #fffdf8);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.grid-faq {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin: 0;
    z-index: 2;
}

.grid-item-faq {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 8px;
    color: #ffffff;
    text-align: left;
}

.grid-item-faq h3 {
    font-size: 18px;
    font-family: azonix;
    margin: 0px 0;
    background: linear-gradient(90deg, #E99A4E, #fffdf8);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.grid-item-faq p {
    font-family: neuropolitical;
    font-size: 14px;
    line-height: 22px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .container-faq {
        padding: 100px 50px; /* Adjust padding */
        height: auto; /* Allow height to adjust */
        flex-direction: column; /* Stack elements */
    }

    .title-faq {
        font-size: 28px; /* Smaller title */
        margin-bottom: 30px; /* Adjust margin */
    }

    .grid-faq {
        grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
    }
}

@media (max-width: 768px) {
    .title-faq {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .grid-faq {
        grid-template-columns: 1fr; /* Single column on smaller screens */
    }

    .grid-item-faq h3 {
        font-size: 16px;
    }

    .grid-item-faq p {
        font-size: 12px;
    }

    .grid-item-faq img {
        width: 25px;
    }
}
