.container-uc {
	display: flex;
	justify-content: center;
	align-items: center;
	background: url('../../assets/images/bg6.jpg') no-repeat center center/cover;
	background-attachment: fixed;
	padding: 100px 120px;
	color: white;
	margin: auto;
	position: relative;
	overflow: hidden;
}


.overlay-uc {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 1, 14, 0.9);
	z-index: 1;
}

.inner-container-uc {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 50px;
	position: relative;
	z-index: 2;
	text-align: left;
}

.title-uc {
	font-size: 32px;
	font-family: azonix;
	margin: 0;
	margin-bottom: 10px;
	padding: 0;
	text-align: center;
	background: linear-gradient(90deg, #E99A4E, #fffdf8);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.subtitle-uc {
	margin: auto;
	width: 60%;
	text-align: center;
	font-family: neuropolitical;
	font-size: 14px;
}








.grid-uc {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 30px;
	margin: 0;
	z-index: 2;
}

.grid-uc > div:nth-child(odd) {
	grid-column: span 2;
}

.grid-uc > div:nth-child(odd):nth-of-type(1) {
	grid-column: span 3;
}

.grid-uc > div:nth-child(even):nth-of-type(even) {
	grid-column: span 3;
}

.grid-uc > div:nth-child(even):nth-of-type(2) {
	grid-column: span 2;
}



.grid-item-uc {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	height: 250px;
	border-radius: 20px;
	color: #000;
	text-align: left;
	overflow: hidden;
	position: relative;
}

.img-1-uc {
	background: url('../../assets/images/people-with-vr.png') no-repeat bottom right;
	background-size: contain;
	width: 100%;
	height: 100%;
}
.img-2-uc {
	background: url('../../assets/images/vr-person.png') no-repeat bottom right;
	background-size:contain;
	width: 100%;
	height: 70%;
}
.img-3-uc {
	background: url('../../assets/images/house.png') no-repeat bottom right;
	background-size: contain;
}
.img-4-uc {
	background: url('../../assets/images/pack.png') no-repeat bottom right;
	background-size: contain;
}


.grid-item-uc-1 { 
	background: linear-gradient(to right, rgb(233, 154, 78), rgba(233, 154, 78, 0.7)), 
							radial-gradient(circle at 100% 50%, rgb(28, 19, 6), transparent);
}
.grid-item-uc-2 { 
	background: linear-gradient(to right, #fff, #99999986), 
							radial-gradient(circle at 100% 50%, rgb(255, 173, 57), transparent);
}
.grid-item-uc-3 { 
	background: linear-gradient(to right, #fff, #99999958), 
							radial-gradient(circle at 100% 50%, rgb(33, 114, 255), transparent);
}
.grid-item-uc-4 { 
	background: linear-gradient(to right, #0073FF, #0073ff86), 
							radial-gradient(circle at 100% 50%, rgb(255, 255, 255), transparent);
}


.grid-item-left-uc {
	flex: 1;
	padding: 20px;
}
.grid-item-left-uc h3 {
	margin-bottom: 10px;
	font-size: 18px;
	font-family: azonix;
	color: #000;
}
.grid-item-img-uc {
	flex:1;
	width: 700px;
	height: 250px;
	overflow: hidden;
}



.grid-item-uc p {
    font-family: neuropolitical;
    font-size: 14px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .container-uc {
        padding: 100px 50px;
        height: auto;
        flex-direction: column;
    }

    .title-uc {
        font-size: 28px;
        /* margin-bottom: 20px; */
    }

    .grid-uc {
        grid-template-columns: repeat(5, 1fr);
		grid-column: span 1;
    }
}

@media (max-width: 768px) {
    .title-uc {
        font-size: 24px;
        margin-bottom: 0px;
    }

    .grid-uc {
        grid-template-columns: 1fr;
    }
	.grid-item-uc {
		height: 180px;
	}

    .grid-item-uc h3 {
        font-size: 16px;
    }

    .grid-item-uc p {
        font-size: 12px;
    }

    .grid-item-uc img {
        width: 25px;
    }
}
