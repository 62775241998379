.footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 50px;
  background-color: #09090B;
  color: white;
}
.footer h2 {
  font-family: neuropolitical;
  font-size: 18px;
  width: 40%;
  margin: auto;
}

.social-icons-f {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  /* margin: 10px 0; */
}

.social-icon {
  display: flex;
	justify-content: center;
	width: 50px;
	height: 50px;
	align-items: center;
  background-color: #201D1D;
	border: 2px solid #393636;
	border-radius: 10px;
	transition: scale .5s;
}
.social-icon:hover {
  background-color: #26104b;
	border: 2px solid #fff;
	scale: 1.1;
}

.copyright {
  margin-top: 0;
  font-family: neuropolitical;
  background-color: #393636;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
  
/* Media Queries for Responsiveness */
@media (max-width: 1024px) {

}

@media (max-width: 768px) {
  .footer h2 {
    width: 100%;
    margin: auto;
  }
}
