.container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  background: url('../../assets/images/bg2.jpg') no-repeat center center/cover;
  padding: 50px 120px;
  color: white;
  margin: auto;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
}

.left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;
  z-index: 2;
  padding: 0 40px;
}

.image-left,
.image-right {
  width: 100%;
  height: 100%;
  background-size: contain; /* Adjust for responsiveness */
  background-repeat: no-repeat;
  background-position: center;
}

.image-left {
  background-image: url('../../assets/images/girl-img.png');
}

.image-right {
  background-image: url('../../assets/images/girl-img-2.png');
}

.right {
  font-family: 'Neuropolitical';
  z-index: 2;
}



.left, .right {
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start slightly below */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition */
}

.left {
  animation: slideFade 1.5s forwards; /* Slide and fade in */
}

.right {
  animation: slideFade 1.5s 0.4s forwards; /* Delay for the right element */
}

@keyframes slideFade {
  to {
      opacity: 1; /* Fully visible */
      transform: translateY(0); /* Move to original position */
  }
}




.inner-div {
  padding: 20px 0;
}

.inner-div p {
  font-size: 14px;
  line-height: 25px;
}

.right h2 {
  font-size: 32px;
  font-family: azonix;
  margin: 0;
}

.subtitle {
  font-size: 24px;
  font-family: azonix;
  margin: 0;
  padding: 20px 0;
}

.right h2,
.subtitle {
  background: linear-gradient(90deg, #E99A4E, #fffdf8);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.box-div {
  display: flex;
  gap: 20px; /* Space between the boxes */
}

.box-div div {
  padding: 20px;
  border: 1px solid #E99A4E;
  border-radius: 20px;
  font-size: 14px;
  line-height: 25px;
  animation: bounce 1s infinite alternate; /* Infinite bounce animation */
}

/* Delayed animation for the second item */
.box-div div:nth-child(2) {
  animation-delay: 0.2s; /* Delay for the second item */
}

@keyframes bounce {
  0% {
      transform: translateY(0); /* Start at original position */
  }
  100% {
      transform: translateY(-10px); /* Move up 10px */
  }
}


/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .container {
      flex-direction: column;
      padding: 50px 20px;
  }

  .left {
      flex-direction: column;
      padding: 0 20px;
  }

  .inner-div {
      text-align: left;
  }

  .right h2 {
      font-size: 28px;
  }

  .subtitle {
      font-size: 20px;
  }
  .desc {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .right h2 {
      font-size: 24px; /* Further decrease heading size */
  }

  .subtitle {
      font-size: 18px; /* Further decrease subtitle size */
  }

  .inner-div p {
      font-size: 12px; /* Decrease paragraph size */
  }

  .box-div {
      flex-direction: column; /* Stack box items vertically */
  }
}
@media (max-width: 480px) {
  .left {
    display: none;
  }

}