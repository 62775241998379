.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 200;
  opacity: 0; /* Start hidden */
  transition: opacity 0.3s ease; /* Transition for fade effect */
  visibility: hidden; /* Hide the overlay */
}

.popup-overlay.fade-in {
  opacity: 1; /* Fully visible */
  visibility: visible; /* Ensure the overlay is visible */
}

.popup-content {
	display: flex;
	flex-direction: column;
	gap: 20px;
	background: linear-gradient(30deg, #E99A4E, #fffdf8, #fffdf8);
	border-radius: 15px;
	padding: 20px;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
	width: 300px;
	text-align: center;
}

.popup-content h2 {
	margin: 0;
	color: black;
	font-family: azonix;
	font-size: 32px;
}
.popup-content p {
	margin: 0;
	color: black;
	font-family: neuropolitical;
}

.popup-icon-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 10px;
}

.popup-icon {
	display: flex;
	justify-content: center;
	width: 50px;
	height: 50px;
	align-items: center;
  	background-color: #201D1D;
	border: 2px solid #393636;
	border-radius: 10px;
	transition: scale .5s;
}
.popup-icon:hover {
	background-color: #26104b;
	border: 2px solid #fff;
	scale: 1.1;
}

.popup-close-btn {
	background-color: #10091c;
	border: none;
	border-radius: 5px;
	color: white;
	padding: 10px 15px;
	cursor: pointer;
	transition: background 0.3s;
}

.popup-close-btn:hover {
	background-color: #26104b;
}
