.container-wo {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../assets/images/bg6.jpg') no-repeat center center/cover;
  background-attachment: fixed;
  padding: 100px 120px;
  color: white;
  margin: auto;
  position: relative;
}

.overlay-wo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(4, 1, 14, 0.8);
}

.left-wo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Use flex-start for left alignment */
  justify-content: center;
  gap: 10px;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.left-wo h2 {
  font-size: 32px;
  font-family: azonix;
  margin: 0;
}

.subtitle-wo {
  font-size: 24px;
  font-family: 'Neuropolitical';
  margin: 0;
  padding: 20px 0;
  background: linear-gradient(90deg, #E99A4E, #fffdf8);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.desc {
  font-family: 'Neuropolitical';
  line-height: 25px;
}

.right-wo {
  flex: 1;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  gap: 20px; /* Space between the images */
}

.right-wo img {
  width: 100%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  animation: bounce 1s infinite alternate; /* Infinite bounce animation */
}

/* Delayed animation for the second image */
.right-wo img:nth-child(2) {
  animation-delay: 0.2s; /* Delay for the second image */
}

@keyframes bounce {
  0% {
      transform: translateY(0); /* Start at original position */
  }
  100% {
      transform: translateY(-10px); /* Move up 10px */
  }
}

.right-wo img {
  position: absolute;
  width: 250px;
  max-width: 100%;
  height: auto;
}

.img-1 {
  top: 10%;
  left: 10%;
}

.img-2 {
  bottom: 10%;
  right: 20%;
}

.right-wo h2 {
  font-size: 32px;
  font-family: azonix;
  margin: 0;
}

.box-div-wo {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.box-div-wo div {
  padding: 20px;
  border: 2px solid #E99A4E;
  border-radius: 20px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .container-wo {
      flex-direction: column; /* Stack elements on smaller screens */
      padding: 50px 20px; /* Adjust padding */
  }

  .left-wo {
      align-items: left; /* Center align on smaller screens */
      padding: 0 20px; /* Adjust padding */
  }

  .left-wo h2 {
      font-size: 28px; /* Smaller heading */
  }

  .subtitle-wo {
      font-size: 20px;
  }
}

@media (max-width: 768px) {
  .left-wo h2 {
      font-size: 24px; /* Further decrease heading size */
  }

  .subtitle-wo {
      font-size: 18px;
  }

  .desc {
      font-size: 14px;
      text-align: left;
  }

  .box-div-wo {
      flex-direction: column;
  }

  .right-wo img {
      width: 200px;
  }
  .img-1 {
    top: -10%;
    left: 10%;
  }
  
  .img-2 {
    bottom: -5%;
    right: 10%;
  }
  
}
